import _interceptor from "../interceptor";
import _uriTemplate from "../util/uriTemplate";
import _mixin from "../util/mixin";
var exports = {};
var interceptor, uriTemplate, mixin;
interceptor = _interceptor;
uriTemplate = _uriTemplate;
mixin = _mixin;
/**
 * Applies request params to the path as a URI Template
 *
 * Params are removed from the request object, as they have been consumed.
 *
 * @see https://tools.ietf.org/html/rfc6570
 *
 * @param {Client} [client] client to wrap
 * @param {Object} [config.params] default param values
 * @param {string} [config.template] default template
 *
 * @returns {Client}
 */

exports = interceptor({
  init: function (config) {
    config.params = config.params || {};
    config.template = config.template || "";
    return config;
  },
  request: function (request, config) {
    var template, params;
    template = request.path || config.template;
    params = mixin({}, request.params, config.params);
    request.path = uriTemplate.expand(template, params);
    delete request.params;
    return request;
  }
});
export default exports;